<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="mt-20">
              <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <div>
                  <div class="mt-3">
                    <h3
                      class="text-3xl font-bold lg:text-3xl xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-green-100 via-pink-200 to-purple-100"
                    >
                      Start show performance
                    </h3>
                  </div>

                  <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />

                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-3"
                  >
                    Welcome to our free prompt section, where you can discover
                    and explore a variety of prompts designed to spark your
                    creativity and inspire your next project. Whether you're an
                    artist, writer, or programmer, our free prompt section is a
                    great way to discover new ideas and experiment with
                    different approaches.
                  </h3>

                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-3"
                  >
                    Our team has carefully curated a selection of high-quality
                    prompts that cover a wide range of themes and topics. From
                    sci-fi and fantasy to romance and horror, our prompts offer
                    something for everyone. And the best part is that they're
                    all completely free to use!
                  </h3>

                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-3"
                  >
                    So whether you're looking for a fun way to pass the time, or
                    you're looking for inspiration for your next project, be
                    sure to check out our free prompt section. You never know
                    what ideas you might discover!
                  </h3>

                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-3"
                  >
                    Get selling in just 2 minutes.
                  </h3>
                  <div class="text-gray-200 mt-5">
                    <!-- <button
                        class="w-42 mt-2 flex-none text-2xl text-base px-6 py-2 border rounded text-gray-700 border-none bg-gradient-to-r from-gray-300 via-gray-100 to-gray-300"
                      >
                        Sell a prompt
                      </button> -->

                    <h3
                      class="text-xs font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-gray-300 mt-2"
                    >
                      Please read our
                      <a href="#" style="text-decoration-line: underline"
                        >prompt submission guidelines
                      </a>
                      before submitting your prompt so you understand what
                      prompts can be sold on PromptDue.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700 mt-10" />
          </div>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl text-gray-200"
      >
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <div>
                <div class="mt-3">
                  <h3
                    class="text-3xl font-bold lg:text-3xl xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-pink-100 via-purple-100 to-blue-100"
                  >
                    Prompt Detail
                  </h3>
                </div>

                <div class="mt-3">
                  <h3 class="text-sm font-bold">
                    Tell us about the prompt you want to sell.
                  </h3>

                  <h3 class="text-sm mt-2 font-bold">
                    These details are not final. Our team will make edits if it
                    goes live.
                  </h3>
                </div>

                <div class="mt-5">
                  <h3 class="text-sm font-bold text-gray-100">Prompt Type</h3>

                  <h3
                    class="text-sm font-bold text-gray-300"
                    style="font-size: 12px"
                  >
                    Select the type of prompt you want to sell
                  </h3>

                  <div class="mt-2">
                    <select
                      class="text-xl text-gray-200 p-2 bg-gray-700 rounded-lg"
                      v-model="prompt_type"
                    >
                      <!-- <option>GPT-3</option>
                      <option>ChatGPT</option>
                      <option>DALL-E</option> -->
                      <option>NIGHTCAFE</option>
                      <option>DALL-E</option>
                      <option>Midjourney</option>
                      <option>Stable Diffusion</option>
                      <option>PromptBase</option>
                      <option>BlueWillow</option>
                    </select>
                  </div>

                  <div class="mt-5">
                    <h3 class="text-sm font-bold text-gray-100">Title</h3>
                    <h3
                      class="text-sm font-bold text-gray-300 mt-1"
                      style="font-size: 12px"
                    >
                      Suggest a title for this prompt.
                    </h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                    >
                      <input
                        placeholder="Title"
                        v-model="title"
                        class="flex-grow outline-none text-sm bg-transparent"
                      />
                    </div>
                    <div class="max-w-xl">
                      <div class="flex-frow text-right text-sm mt-1">
                        0 / 40
                      </div>
                    </div>
                  </div>

                  <div class="mt-5">
                    <h3 class="text-sm font-bold text-gray-100">Description</h3>
                    <h3
                      class="text-sm font-bold text-gray-300 mt-1"
                      style="font-size: 12px"
                    >
                      Describe what your prompt does to a potential buyer. A
                      more detailed description will increase your sales.
                    </h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                    >
                      <textarea
                        v-model="description"
                        id="message"
                        rows="4"
                        class="flex-grow outline-none text-sm bg-transparent"
                        placeholder="Write your thoughts here..."
                      ></textarea>
                    </div>

                    <div class="max-w-xl">
                      <div class="flex-frow text-right text-sm mt-1">
                        0 / 200
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col items-center">
                <iframe
                  class="h-96 w-90"
                  src="https://www.youtube.com/embed/QljNc1lI7iw"
                  title="Prompt art exhibition that share your prompts to the digital world"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div class="">
              <hr
                class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700 mt-10"
              />
            </div>

            <div class="grid grid-cols-1 gap-2 lg:grid-cols-1">
              <div>
                <div class="mt-10">
                  <h3
                    class="text-3xl font-bold lg:text-3xl xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-pink-100 via-purple-100 to-blue-100"
                  >
                    Prompt File
                  </h3>
                </div>

                <div class="mt-3">
                  <h3 class="text-sm font-bold">
                    Copy and paste your Midjourney prompt.
                  </h3>

                  <h3 class="text-sm mt-2 font-bold">
                    *Include all your settings as tags within the prompt (e.g.
                    --v 4 --q 2)
                  </h3>
                </div>

                <div class="mt-5">
                  <div class="mt-5">
                    <h3 class="text-sm font-bold text-gray-100">*Prompt</h3>
                    <h3
                      class="text-sm font-bold text-gray-300 mt-1"
                      style="font-size: 12px"
                    >
                      Put any variables in [square brackets].
                    </h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                    >
                      <textarea
                        v-model="prompt"
                        id="message"
                        rows="4"
                        class="flex-grow outline-none text-sm bg-transparent"
                        placeholder="Write your thoughts here..."
                      ></textarea>
                    </div>

                    <div class="max-w-xl">
                      <div class="flex-frow text-right text-sm mt-1">
                        0 / 400
                      </div>
                    </div>
                  </div>

                  <div class="mt-3" v-if="prompt_type == 'Stable Diffusion'">
                    <h3 class="text-sm font-bold text-gray-100">
                      Extra prompt Instructions
                    </h3>
                    <h3
                      class="text-sm font-bold text-gray-300 mt-1"
                      style="font-size: 12px"
                    >
                      Any extra tips or examples for the buyer on how to use
                      this prompt.
                    </h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                    >
                      <textarea
                        v-model="prompt_instructions"
                        id="message"
                        rows="4"
                        class="flex-grow outline-none text-sm bg-transparent"
                        placeholder="Write your thoughts here..."
                      ></textarea>
                    </div>

                    <div class="max-w-xl">
                      <div class="flex-frow text-right text-sm mt-1">
                        0 / 400
                      </div>
                    </div>

                    <h3 class="text-sm font-bold text-gray-100 mt-10">Model</h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                    >
                      <input
                        v-model="model_name"
                        placeholder="Model Name"
                        class="flex-grow outline-none text-sm bg-transparent"
                      />
                    </div>
                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                    >
                      <input
                        v-model="url_download"
                        placeholder="URL Download"
                        class="flex-grow outline-none text-sm bg-transparent"
                      />
                    </div>

                    <h3 class="text-sm font-bold text-gray-100 mt-5">
                      Sampler
                    </h3>
                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                    >
                      <input
                        v-model="sampler"
                        placeholder="Sampler Name"
                        class="flex-grow outline-none text-sm bg-transparent"
                      />
                    </div>

                    <h3 class="text-sm font-bold text-gray-100 mt-5">
                      Image width
                    </h3>
                    <vue-slider
                      v-model="imgWidht"
                      v-bind="options"
                      class="sm:max-w-xl md:w-full lg:max-w-screen-sm"
                    ></vue-slider>

                    <h3 class="text-sm font-bold text-gray-100 mt-5">
                      Image height
                    </h3>
                    <vue-slider
                      v-model="imgHeight"
                      v-bind="options"
                      class="sm:max-w-xl md:w-full lg:max-w-screen-sm"
                    ></vue-slider>

                    <h3 class="text-sm font-bold text-gray-100 mt-5">
                      Cfg Scale
                    </h3>
                    <vue-slider
                      v-model="cfgScale"
                      class="sm:max-w-xl md:w-full lg:max-w-screen-sm"
                    ></vue-slider>

                    <h3 class="text-sm font-bold text-gray-100 mt-5">Steps</h3>
                    <vue-slider
                      v-model="steps"
                      class="sm:max-w-xl md:w-full lg:max-w-screen-sm"
                    ></vue-slider>

                    <h3 class="text-sm font-bold text-gray-100 mt-5">
                      Seed (optional)
                    </h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                    >
                      <input
                        v-model="seed"
                        placeholder="Random seed"
                        class="flex-grow outline-none text-sm bg-transparent"
                      />
                    </div>

                    <h3 class="text-sm font-bold text-gray-100 mt-5">
                      CLIP Guidance
                    </h3>
                    <input type="checkbox" v-model="CLIP" />

                    <h3 class="text-sm font-bold text-gray-100 mt-5">
                      Negative Prompt
                    </h3>

                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                    >
                      <input
                        v-model="negative_prompt"
                        placeholder="Negative Prompt"
                        class="flex-grow outline-none text-sm bg-transparent"
                      />
                    </div>
                  </div>

                  <div class="mt-10">
                    <h3 class="text-sm font-bold text-gray-100">
                      *Upload 4 to 7 example images generated by this prompt (no
                      collages or edits)
                    </h3>
                    <h3
                      class="text-sm font-bold text-gray-300 mt-3"
                      style="font-size: 12px"
                    >
                      Only upload your images generated by Midjourney.
                    </h3>
                    <h3
                      class="text-sm font-bold text-gray-300"
                      style="font-size: 12px"
                    >
                      Prompts with more example images usually get more sales.
                    </h3>
                  </div>

                  <div
                    class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                  >
                    <input
                      v-model="img_1"
                      placeholder="Url img 1"
                      class="flex-grow outline-none text-sm bg-transparent"
                    />
                  </div>
                  <div
                    class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                  >
                    <input
                      v-model="img_2"
                      placeholder="Url img 2"
                      class="flex-grow outline-none text-sm bg-transparent"
                    />
                  </div>
                  <div
                    class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                  >
                    <input
                      v-model="img_3"
                      placeholder="Url img 3"
                      class="flex-grow outline-none text-sm bg-transparent"
                    />
                  </div>
                  <div
                    class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                  >
                    <input
                      v-model="img_4"
                      placeholder="Url img 4"
                      class="flex-grow outline-none text-sm bg-transparent"
                    />
                  </div>
                  <div
                    class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                  >
                    <input
                      v-model="img_5"
                      placeholder="Url img 5"
                      class="flex-grow outline-none text-sm bg-transparent"
                    />
                  </div>
                  <div
                    class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                  >
                    <input
                      v-model="img_6"
                      placeholder="Url img 6"
                      class="flex-grow outline-none text-sm bg-transparent"
                    />
                  </div>
                  <div
                    class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                  >
                    <input
                      v-model="img_7"
                      placeholder="Url img 7"
                      class="flex-grow outline-none text-sm bg-transparent"
                    />
                  </div>

                  <h3 class="text-sm font-bold text-gray-100 mt-2">
                    *Midjourney Profile
                  </h3>
                  <h3
                    class="text-sm font-bold text-gray-300"
                    style="font-size: 12px"
                  >
                    Copy the midjourney.com/app/users link to your profile
                    (watch our video if you can't find this). You'll need an
                    active Midjourney subscription to get this link.
                  </h3>
                </div>
              </div>
            </div>

            <div class="">
              <hr
                class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700 mt-10"
              />

              <div class="flex flex-col items-center mt-14">
                <button
                  @click="createPrompt()"
                  class="w-42 mt-2 flex-none text-2xl text-base px-6 py-2 border rounded text-gray-700 border-none bg-gradient-to-r from-green-500 to-blue-500"
                >
                  Public for free
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickFooter />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";

import Prompt from "@/api/prompt";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  data() {
    return {
      prompt_type: "Midjourney",
      title: null,
      description: null,
      price: "$ 1.99",
      prompt: null,
      prompt_instructions: null,
      category: null,
      img_1: null,
      img_2: null,
      img_3: null,
      img_4: null,
      img_5: null,
      img_6: null,
      img_7: null,
      imgWidht: 0,
      imgHeight: 0,
      cfgScale: 0,
      steps: 0,
      model_name: null,
      url_download: null,
      seed: null,
      negative_prompt: null,
      CLIP: true,
      sampler: null,
      options: {
        min: 0,
        max: 2048,
      },
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
    VueSlider,
  },

  computed: mapGetters(["getUser"]),

  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },

    async createPrompt() {
      var address = "free";

      if (this.getUser) {
        address = this.getUser.address;
      } else {
      }

      await Prompt.create({
        address: address,
        free: "free",
        prompt_type: this.prompt_type,
        title: this.title,
        description: this.description,
        price: this.price,
        prompt: this.prompt,
        prompt_instructions: this.prompt_instructions,
        category: this.category,
        img_1: this.img_1,
        img_2: this.img_2,
        img_3: this.img_3,
        img_4: this.img_4,
        img_5: this.img_5,
        img_6: this.img_6,
        img_7: this.img_7,
        imgWidht: this.imgWidht,
        imgHeight: this.imgHeight,
        cfgScale: this.cfgScale,
        steps: this.steps,
        model_name: this.model_name,
        url_download: this.url_download,
        seed: this.seed,
        negative_prompt: this.negative_prompt,
        CLIP: this.CLIP,
        sampler: this.sampler,
      })
        .then((res) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });

          setTimeout(() => {
            this.$router.push("/funds");
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
